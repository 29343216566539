import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";

const scrollDownIndicator = document.getElementById("scroll-down");

const skills = [
  "ThreeJS",
  "React",
  "NextJS",
  "CSS3",
  "Typescript",
  "PandaCSS",
  "CSS Modules",
  "React Query",
  "Zustand",
  "Hydrogen",
  "Shopify",
  "Stripe",
  "NextAuth",
  "React Spring",
  "SASS",
  "RadixUI",
  "Jest",
  "Storybook",
  "MaterialUI",
  "EthersJS",
  "MeshJS",
  "Web3Auth",
  "Formik",
  "React Hook Form",
  "NodeJS",
  "PHP",
  "Python",
  "MySQL",
  "Github",
  "Vercel",
  "Figma",
  "Sanity",
];
const fontLoader = new FontLoader();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Skills group
 */

const skillsGroup = new THREE.Group();
skillsGroup.position.set(0, 0, 0);
scene.add(skillsGroup);

/**
 * Galaxy
 */
const parameters = {};
parameters.count = 100000;
parameters.size = 0.01;
parameters.radius = 3;
parameters.branches = 3;
parameters.spin = 1;
parameters.randomness = 0.2;
parameters.randomnessPower = 3;
parameters.insideColor = "#ff6030";
parameters.outsideColor = "#1b3984";

let geometry = null;
let material = null;
let points = null;

const textureLoader = new THREE.TextureLoader();
const particleTexture = textureLoader.load("/textures/particles/5.png");

/**
 * Scroll
 */

let scrollY = window.scrollY;

const lenis = new Lenis({
  duration: 4,
  gestureDetection: true,
});

lenis.on("scroll", (e) => {
  scrollY = e.animatedScroll;
});

// Disable scroll restoration
if ("scrollRestoration" in history) {
  history.scrollRestoration = "manual";
}

window.onload = function () {
  // Scroll to the top of the page on reload
  window.scrollTo(0, 0);
};

const enableScrolling = () => {
  document.getElementById("content").style.height = "auto";
  document.getElementById("content").style.overflow = "visible";
  window.scrollTo(0, 0);
  scrollDownIndicator.classList.add("visible");
};

/**
 * Cursor
 */
const cursor = {};
cursor.x = 0;
cursor.y = 0;

window.addEventListener("mousemove", (event) => {
  cursor.x = event.clientX / sizes.width - 0.5;
  cursor.y = event.clientY / sizes.height - 0.5;
});

let stars = null;

const generateStars = () => {
  /**
   * Geometry
   */
  const geometry = new THREE.BufferGeometry();

  const positions = new Float32Array(parameters.count * 3);

  for (let i = 0; i < 5000; i++) {
    const i3 = i * 3;

    positions[i3] = (Math.random() - 0.5) * 100;
    positions[i3 + 1] = (Math.random() - 0.5) * 100;
    positions[i3 + 2] = (Math.random() - 0.5) * 100;
  }

  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

  /**
   * Material
   */
  const startsMaterial = new THREE.PointsMaterial({
    size: parameters.size,
    sizeAttenuation: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
  });

  /**
   * Points
   */
  stars = new THREE.Points(geometry, startsMaterial);
  scene.add(stars);
};
generateStars();

const generateGalaxy = () => {
  // Destroy old galaxy
  if (points !== null) {
    geometry.dispose();
    material.dispose();
    scene.remove(points);
  }

  /**
   * Geometry
   */
  geometry = new THREE.BufferGeometry();

  const positions = new Float32Array(parameters.count * 3);
  const colors = new Float32Array(parameters.count * 3);

  const colorInside = new THREE.Color(parameters.insideColor);
  const colorOutside = new THREE.Color(parameters.outsideColor);

  for (let i = 0; i < parameters.count; i++) {
    // Position
    const i3 = i * 3;

    const radius = 3;

    const randomAngle = Math.random() * 360;

    const randomXOffset =
      Math.pow(Math.random(), 2) * (Math.random() < 0.5 ? -1 : 1) * 0.5;
    const randomYOffset =
      Math.pow(Math.random(), 2) * (Math.random() < 0.5 ? -1 : 1) * 0.5;
    const randomZOffset =
      Math.pow(Math.random(), 2) * (Math.random() < 0.5 ? -1 : 1) * 0.5;

    const x = Math.cos(randomAngle) * radius;
    const y = 0;
    const z = Math.sin(randomAngle) * radius;

    positions[i3] = x + randomXOffset;
    positions[i3 + 1] = randomYOffset;
    positions[i3 + 2] = z + randomZOffset;

    // Color
    const mixedColor = colorInside.clone();
    const distance = new THREE.Vector3(x, y, z).distanceTo(
      new THREE.Vector3(positions[i3], positions[i3 + 1], positions[i3 + 2])
    );
    mixedColor.lerp(colorOutside, distance * 2);

    colors[i3] = mixedColor.r;
    colors[i3 + 1] = mixedColor.g;
    colors[i3 + 2] = mixedColor.b;
  }

  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
  geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

  /**
   * Material
   */
  material = new THREE.PointsMaterial({
    size: parameters.size,
    sizeAttenuation: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    vertexColors: true,
    map: particleTexture,
  });

  /**
   * Points
   */
  points = new THREE.Points(geometry, material);
  scene.add(points);
};

generateGalaxy();

const skillsObjectsArray = [];

const generateSkills = () => {
  const materialTexture = textureLoader.load("textures/mcap13.png");
  materialTexture.colorSpace = THREE.SRGBColorSpace;

  // const materialText = new THREE.MeshMatcapMaterial({
  // matcap: materialTexture,
  // });
  const materialText = new THREE.MeshStandardMaterial({
    color: "#fff",
  });

  const angleStep = (Math.PI * 2) / (skills.length + 1);
  let angle = 0;

  fontLoader.load("/fonts/space4.json", (font) => {
    for (let i = 0; i < skills.length; i++) {
      const skillGeometry = new TextGeometry(skills[i], {
        font: font,
        size: 0.5,
        depth: 0.01,
      });
      skillGeometry.center();

      const skill = new THREE.Mesh(skillGeometry, materialText);

      const radius = 3;

      const randomXOffset = Math.pow(Math.random(), 2) * 0.5;
      const randomYOffset =
        Math.pow(Math.random(), 2) * (Math.random() < 0.5 ? -1 : 1) * 0.4;
      const randomZOffset = Math.pow(Math.random(), 2) * 0.5;

      const x = Math.cos(angle) * radius;
      const y = 0;
      const z = Math.sin(angle) * radius;

      skill.position.x = x + randomXOffset;
      skill.position.y = randomYOffset;
      skill.position.z = z + randomZOffset;

      skill.lookAt(new THREE.Vector3(0, 0, 100));

      const scale = 0.09;
      skill.scale.set(scale, scale, scale);

      skillsGroup.add(skill);
      skillsObjectsArray.push(skill);
      angle += angleStep;
    }
  });
};

generateSkills();

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight("#ffffff", 3);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight("#ffffff", 1);
directionalLight.position.set(0, 1, 10);
directionalLight.lookAt(new THREE.Vector3(0, 0, 4));
scene.add(directionalLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */

const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 0;
camera.position.y = 1;
camera.position.z = 50;
camera.lookAt(points.position);
cameraGroup.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 5));

/**
 * Initial animation
 */
const initialAnimationTargetPosition = new THREE.Vector3(0, 1, 4.5);
let scrollingEnabled = false;

/**
 * Animate
 */
const clock = new THREE.Clock();

let animationSection = 0;

const leftOffset = sizes.width > 768 ? 5 : 3;

const cameraAnimations = [
  {
    startCameraPosition: new THREE.Vector3(0, 1, 4.5),
    startTargetPosition: new THREE.Vector3(0, 0, 0),
    endCameraPosition: new THREE.Vector3(leftOffset, 3, 0),
    endTargetPosition: new THREE.Vector3(leftOffset, 0, 0),
  },
  {
    startCameraPosition: new THREE.Vector3(leftOffset, 3, 0),
    startTargetPosition: new THREE.Vector3(leftOffset, 0, 0),
    endCameraPosition: new THREE.Vector3(3, 0, 0),
    endTargetPosition: new THREE.Vector3(3, 0, -0.1),
  },
];

const tick = (time) => {
  lenis.raf(time);
  const elapsedTime = clock.getElapsedTime();

  //Skills rotation
  points.rotation.y = -elapsedTime * 0.2;
  if (skillsGroup) {
    skillsGroup.rotation.y = -elapsedTime * 0.2;
  }

  let animationProgress = scrollY / sizes.height;
  if (animationProgress > 1 && animationProgress <= 2) {
    animationProgress -= 1;
  }
  if (animationProgress > 2 && animationProgress <= 3) {
    animationProgress -= 1;
  }

  //Camera animation
  const pageScrollProgress = scrollY / sizes.height;
  let activeCameraAnimation = 0;
  if (pageScrollProgress > 1) {
    activeCameraAnimation = 1;
  }

  const currentAnimation = cameraAnimations[activeCameraAnimation];

  // Animation section
  if (scrollY < sizes.height / 2) {
    animationSection = 0;
  }

  if (scrollY > sizes.height / 2) {
    animationSection = 1;
  }

  if (scrollY > sizes.height) {
    animationSection = 2;
  }

  skillsObjectsArray.forEach((item) => {
    if (!scrollingEnabled) {
      item.lookAt(new THREE.Vector3(0, 1, 100));
    } else {
      if (activeCameraAnimation === 0) {
        const textLookingPoint = new THREE.Vector3().lerpVectors(
          new THREE.Vector3(0, 0, 100),
          new THREE.Vector3(3, 100, 10),
          animationProgress
        );
        item.lookAt(textLookingPoint);
      }
      if (activeCameraAnimation === 1) {
        const textLookingPoint = new THREE.Vector3().lerpVectors(
          new THREE.Vector3(3, 100, 10),
          new THREE.Vector3(0, 0, 100),
          animationProgress
        );
        item.lookAt(textLookingPoint);
      }
    }
  });

  //Initial animation
  if (
    !scrollingEnabled &&
    camera.position.distanceTo(initialAnimationTargetPosition) < 0.1
  ) {
    scrollingEnabled = true;
    enableScrolling();
  } else {
    camera.position.lerp(initialAnimationTargetPosition, 0.01);
    camera.lookAt(skillsGroup.position);
  }

  //Scrolling animations
  if (scrollingEnabled && scrollY > 0) {
    camera.position.lerpVectors(
      currentAnimation.startCameraPosition,
      currentAnimation.endCameraPosition,
      animationProgress
    );

    let currentTarget = new THREE.Vector3().lerpVectors(
      currentAnimation.startTargetPosition,
      currentAnimation.endTargetPosition,
      animationProgress
    );

    camera.lookAt(currentTarget);
  }

  // Parallax
  if (stars) {
    const parallaxX = -cursor.x * 4;
    const parallaxY = cursor.y * 4;
    if (animationSection === 0 || animationSection === 2) {
      stars.position.x += (parallaxX - stars.position.x) * 0.05;
      stars.position.y += (parallaxY - stars.position.y) * 0.05;
    }

    if (animationSection === 1) {
      stars.position.x += (parallaxX - stars.position.x) * 0.05;
      stars.position.z += (-parallaxY - stars.position.z) * 0.05;
    }
  }

  // Hide the "scroll down" indicator
  if (
    getComputedStyle(scrollDownIndicator)["opacity"] === "1" &&
    scrollY > 100
  ) {
    scrollDownIndicator.classList.remove("visible");
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
